<template>
  <div class="w-full flex flex-wrap justify-between items-center py-3 px-4 rounded-3xl base-bg-darker-500 mt-4 sticky bottom-8">
    <div>
      <slot name="text"></slot>
    </div>

    <ButtonWithLoader 
      :disabled="disabled"
      :btnText="btnText"
      borderClass="border border-white"
      textClass="text-white"
      padXClass="px-3"
      @nextStep="nextStep"
    />
  </div>
</template>

<script>
  import ButtonWithLoader from '@/components/ButtonWithLoader'

  export default {
    components: {
      ButtonWithLoader
    },
    props: {
      btnText:{
        type: String,
        default: 'Next Step'
      },
      disabled:{
        type: Boolean,
        default: false
      },
    },
    methods: {
      nextStep(){
        this.$emit('nextStep')
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>