<template>
  <div class="flex flex-wrap wrapper">
    <div class="px-4 w-full pb-24 pt-40">
      <p class="text-black font-bold head-title leading-none mb-10">
        <template v-if="isEditMode">
          Change Bucket Details
        </template>
        <template v-else>
          Create a New Bucket
        </template>
      </p>
      <SettingsTitle :title="isEditMode ? 'Change Details for Bucket' : 'Fill in the following details'"/>
      <div class="w-full flex border-b border-dashed border-grey-darker-200">
        <div class="w-2/6 border-r border-grey-darker-200 py-5">
          <span class="font-sansDemi base-font-gray-200 text-xl leading-none">Bucket Name</span>
        </div>
        <div class="w-4/6">
          <input
            :value="bucket.title"
            @blur="setBucketItem({ key: 'title', value: $event.target.value })"
            type="text" 
            class="base-font-gray-200 text-xl leading-none outline-none bucket-input-shadow py-5 pl-8 w-full placeholder-gray-600"
            placeholder="Enter a Bucket Name"
          >
        </div>
      </div>
      <div class="w-full flex border-b border-dashed border-grey-darker-200">
        <div class="w-2/6 border-r border-grey-darker-200 py-5">
          <span class="font-sansDemi base-font-gray-200 text-xl leading-none block mb-2">Bucket Type</span>
          <span class="font-mono text-xs base-font-gray-300">Please select a bucket type</span>
        </div>
        <div class="w-4/6">
          <ul>
            <li
              v-for="(localBucket, index) in bucketTypes"
              :key="localBucket.id"
              class="flex flex-wrap items-center py-5 pl-8 cursor-pointer"
              :class="{
                'border-b border-dashed border-grey-darker-200': (index + 1) < bucketTypes.length,
                'purple-light-bg purple-text': localBucket.bucketType == bucket.bucketType,
                'opacity-50 cursor-not-allowed': localBucket.disabled
              }"
              @click="selectBucketType(localBucket)"
            > 
              <component :is="localBucket.icon" class="mr-3"></component>
              <span>{{ localBucket.title }}</span>
              <div 
                v-if="localBucket.bucketType == bucket.bucketType && isTeamBucketType"
                class="w-full block"
              ></div>
            </li>
          </ul>
        </div>
      </div>
      <div class="w-full flex border-b border-dashed border-grey-darker-200">
        <div class="w-2/6 border-r border-grey-darker-200 py-5">
          <span class="font-sansDemi base-font-gray-200 text-xl leading-none block mb-2">Description</span>
          <span class="font-mono text-xs base-font-gray-300">255 characters max.</span>
        </div>
        <div class="w-4/6">
          <textarea 
            :value="bucket.description"
            @blur="setBucketItem({ key: 'description', value: $event.target.value })"
            name="description" 
            id="description" 
            cols="30" 
            rows="5"
            placeholder="Please enter a description"
            class="w-full h-full outline-none base-font-gray-200 text-xl leading-none outline-none bucket-name py-5 pl-8 w-full placeholder-gray-600 bucket-input-shadow"
          ></textarea>
        </div>
      </div>
      <div class="w-full flex border-b border-dashed border-grey-darker-200">
        <div class="w-2/6 border-r border-grey-darker-200 py-5">
          <span class="font-sansDemi base-font-gray-200 text-xl leading-none block mb-2">Categories</span>
          <span class="font-mono text-xs base-font-gray-300">
            You can select multiple. If nothing relevant shows up, please click on ‘other’
          </span>
        </div>
        <div class="w-4/6">
          <div class="pt-5 pb-3 px-8 flex flex-wrap">
            <div
              v-for="item in categories"
              :key="item.id"
              class="cursor-pointer
                    font-mono text-xs 
                    px-4 py-2 mr-4 mb-2
                    border rounded-lg"
              :class="[ 
                selectedCategories.find(elem => elem.id == item.id) 
                ? 'purple-light-bg purple-text border-purple' 
                : 'base-bg-darker-220 text-black border-grey-lighter' ]"
              @click="selectCategory(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex border-b border-dashed border-grey-darker-200">
        <div class="w-2/6 border-r border-grey-darker-200 py-5">
          <span class="font-sansDemi base-font-gray-200 text-xl leading-none block">Geographic Focus</span>
        </div>
        <div class="w-4/6">
          <multiselect 
            v-model="geographicalfocus" 
            :options="locationsList" 
            selectLabel=""
            label="country"
            trackBy="abbreviation" 
            selectedLabel="" 
            deselectLabel="" 
            placeholder="Pick a Country"
            class="max-w-xs mb-6 my-5 ml-8 base-bg-darker-230 transcriptSelect list-bg-white top-3px h-8 border-0 rounded rounded-list font-mono text-sm text-black outline-none placeholder-black"
          >
            <template slot="option" slot-scope="props">
              <div
                class="flex option__desc text-sm text-black py-2 mx-4 bg-transparent border-b border-dotted border-grey-light">
                {{ props.option.country ? props.option.country : props.option.continent }}
                <span class="flex items-center tag-item px-2 rounded-lg flex ml-4 text-xs">
                  {{ props.option.abbreviation }}
                </span>
              </div>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              {{ option.country ? option.country : option.continent }} {{ option.abbreviation }}
            </template>
          </multiselect>
        </div>
      </div>
      <div class="w-full flex border-b border-dashed border-grey-darker-200">
        <div class="w-2/6 border-r border-grey-darker-200 py-5">
          <span class="font-sansDemi base-font-gray-200 text-xl leading-none block mb-2">Methodology</span>
          <span class="font-mono text-xs base-font-gray-300">if referring to papers, please use a DOI notation</span>
        </div>
        <div class="w-4/6">
          <textarea 
            :value="bucket.methodology"
            @blur="setBucketItem({ key: 'methodology', value: $event.target.value })"
            name="methodology" 
            id="methodology" 
            cols="30" 
            rows="5"
            placeholder="Please enter a methodology"
            class="w-full h-full outline-none base-font-gray-200 text-xl leading-none outline-none bucket-name py-5 pl-8 w-full placeholder-gray-600 bucket-input-shadow"
          ></textarea>
        </div>
      </div>
      <div v-if="isEditMode" class="w-full flex border-b border-dashed border-grey-darker-200">
        <div class="w-2/6 border-r border-grey-darker-200 py-5">
          <span class="font-sansDemi base-font-gray-200 text-xl leading-none block">Delete Bucket</span>
        </div>
        <div class="w-4/6">
          <ButtonWithLoader 
            :disabled="loading"
            btnText="Delete Bucket"
            type="submit"
            bgClass="purple-bg"
            textClass="purple-light font-monoDemi text-sm"
            heigthClass="h-8"
            class="my-5 ml-8"
            @nextStep="showDeleteBucket"
          />
        </div>
      </div>

      <BottomNav 
        @nextStep="addBucket" 
        :btnText="(isUpdateBucket || isEditMode) ? 'Update Bucket' : 'Create Bucket'"
        :disabled="loading"
      />
    </div>
  </div>
</template>

<script>
import SettingsTitle from '@/components/settings/SettingsTitle'
import triangleInShield from '@/components/icons/triangle-in-shield'
import circlesOnCircle from '@/components/icons/circles-on-circle'
import pointInCircles from '@/components/icons/point-in-circles'
import plusInCircles from '@/components/icons/plus-in-circles'
import BottomNav from '@/components/Bucket/BottomNav'
import ButtonWithLoader from '@/components/ButtonWithLoader'
import bucketCategories from '@/data/bucket_categories'
import locations from '@/data/countries'

import { warningToastConfig, bucketsAdminRights, bucketTypes } from '@/helpers/constants'
import ToastMessages from '@/data/toast_messages.json'
import { mapGetters, mapMutations } from 'vuex'
import Hashids from 'hashids'

export default {
  name: 'BucketAdmin',
  components: {
    SettingsTitle,
    triangleInShield,
    circlesOnCircle,
    pointInCircles,
    plusInCircles,
    BottomNav,
    ButtonWithLoader
  },
  data(){
    return{
      loading: false,
      isEditMode: false,
      bucketId: null,
      bucketTypes: bucketTypes,
      selectedTeam: '',
      categories: bucketCategories.categories,
      selectedCategories: [],
      locationsList: [ ...locations.continents.map(elem => {
        return { ...elem, country: elem.continent }
      }), ...locations.countries ],
      geographicalfocus: ''
    }
  },
  computed: {
    ...mapGetters({
      userAppData: 'general/userAppData',
      bucket: 'bucket/bucket',
      isUpdateBucket:'bucket/isUpdateBucket',
      user_meta: 'general/user_meta'
    }),
    isTeamBucketType(){
      return this.bucket.bucketType == this.bucketTypes[1].bucketType
    },
    teamsList(){
      return this.user_meta.teams ? this.user_meta.teams : []
    }
  },
  methods: {
    ...mapMutations({
      setBucketItem: 'bucket/setBucketItem',
      setIsUpdate: 'bucket/setIsUpdate'
    }),
    async showDeleteBucket(){
      if (window.confirm('Are you sure that you want to delete this bucket?')) {
        this.loading = true
        await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/delete_bucket`, {
          bucketId: this.bucketId
        })
        this.loading = false

        const keys = Object.keys(this.bucket)
        keys.forEach(elem => {
          this.setBucketItem({
            key: elem,
            value: elem == 'tags' ? [] : ''
          })
        })
        this.bucketId = null
        this.selectedTeam = ''
        this.selectedCategories = []
        this.geographicalfocus = ''
        this.isEditMode = false
        this.setIsUpdate(false)

        this.$toasted.show('Bucket has been deleted', warningToastConfig)

        this.$router.push(`/buckets`)
      }
    },
    async addBucket() {
      this.loading = true
      if( this.bucket.title.length && this.bucket.bucketType.length){
        let modelData = {
          action: this.isEditMode ? 'update' : 'create',
          type: this.bucket.bucketType,
          name: this.bucket.title,
          description: this.bucket.description,
          methodology: this.bucket.methodology,
          tags: JSON.stringify(this.bucket.tags),
          geographicalfocus: this.geographicalfocus.abbreviation
        }
        
        if(this.isEditMode){
          modelData.bucketId = this.bucketId
        }else{
          const hashids = new Hashids()
          modelData.bucketId = hashids.encode(Date.now())
        }

        if(this.isTeamBucketType){
          modelData.teamId = this.selectedTeam.id
        }

        try {
          let result = null
          if(this.isEditMode){
            result = await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/create-bucket`, modelData)
            this.$toasted.show('Channel Details Updated', warningToastConfig)
            this.loading = false
          } else {
            result = await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/create-bucket`, modelData)

            if(!this.isUpdateBucket){
              this.setBucketItem({
                key: 'bucketId',
                value: result.data.bucketId
              })
            }
            if(!this.isUpdateBucket) this.setIsUpdate(true)

            this.$router.push(`/bucket-admin-created/${result.data.bucketId}`)
          }
        } catch (error) {
          this.$toasted.show(error, warningToastConfig)
          this.loading = false
        }
      } else {
        this.$toasted.show(ToastMessages.buckets.requiredFields, warningToastConfig)
        this.loading = false
      }
    },
    selectBucketType(bucket){
      if(!bucket.disabled){
        const neededBucket = this.bucketTypes.find(elem => elem.id == bucket.id)
        this.setBucketItem({
          key: 'bucketType',
          value: neededBucket.bucketType
        })
      }
    },
    async getTeams(){
      return (await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-team`)).data
    },
    selectCategory(category){
      const isSelected = this.selectedCategories.find(elem => elem.id == category.id)
      if(isSelected){
        this.selectedCategories = this.selectedCategories.filter(elem => elem.id != category.id)
      }else{
        this.selectedCategories.push(category)
      }
      this.setBucketItem({
        key: 'tags',
        value: this.selectedCategories
      })
    }
  },
  async mounted(){
    // const userPlan = bucketsAdminRights[this.userAppData.plan]
    if(this.$route.params.id){
      this.isEditMode = true
      const { data } = await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get-solo-bucket/${this.$route.params.id}`)
      this.bucketId = data.bucketId
      this.setBucketItem({
        key: 'bucketType',
        value: data.altType
      })
      this.setBucketItem({
        key: 'title',
        value: data.name
      })
      this.setBucketItem({
        key: 'description',
        value: data.description
      })
      this.setBucketItem({
        key: 'methodology',
        value: data.methodology
      })
      if(data.teamId) this.selectedTeam = { id: data.teamId }
      if(data.tags) this.selectedCategories = JSON.parse(data.tags)
      if(data.geographicalfocus) this.geographicalfocus = this.locationsList.find(elem => elem.abbreviation == data.geographicalfocus)
    }

    const userPlan = bucketsAdminRights.raditube_editor
    const data = await this.getTeams() 

    this.bucketTypes.forEach(elem => {
      if(userPlan.indexOf(elem.value) == -1){
        elem.disabled = true
      }else if((elem.bucketType == 'team' || elem.bucketType == 'private') && !data.team){
        elem.disabled = true
      }
    })  
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if(from.path.indexOf('bucket-admin') == -1){
        const keys = Object.keys(vm.bucket)
        keys.forEach(elem => {
          vm.setBucketItem({
            key: elem,
            value: elem == 'tags' ? [] : ''
          })
        })
        vm.setIsUpdate(false)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
</style>